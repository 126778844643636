<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    :show-close="true"
    :center="true"
    :before-close="handleClose"
    title="公告详情"
    width="500px"
  >
    <div>
      <el-form ref="form" :model="formData"  :rules="rules" label-width="100px">

       <el-form-item label="标题" prop="title">
         <el-input v-model="formData.title" ></el-input>
       </el-form-item>

       <el-form-item label="内容" prop="content">
         <el-input v-model="formData.content" type="textarea"></el-input>
       </el-form-item>
    

       <el-form-item label="用户" prop="user_app_ids"  >
         <el-select v-model="formData.user_app_ids" placeholder="请选择" multiple clearable>
           <el-option :label="item.alias_name" :value="item.user_app_id" v-for="(item,index) in addressDatas" :key="index"></el-option>
         </el-select>
       </el-form-item>

       <!--1企业内，2可对外，3不能分享文章内容-->
       <el-form-item label="分享设置" prop="type" clearable >
         <el-select v-model="formData.type" placeholder="请选择"  clearable>
           <el-option label="企业内部" value="1" ></el-option>
           <el-option label="可对外" value="2" ></el-option>
           <el-option label="不能分享" value="3" ></el-option>


         </el-select>
       </el-form-item>
        <!--<el-form-item label="发送时间" prop="action_time" >
          <el-date-picker
              v-model="formData.action_time	"
              type="date"
              placeholder="选择日期">
            </el-date-picker>
        </el-form-item>

      <div class="footer">
         <el-button size="medium" type="primary" @click="submit" >提交</el-button>
         <el-button size="medium" type="primary" @click="cancel" >关闭</el-button>
       </div>-->
     </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </span>


  </el-dialog>
</template>

<script>

import { companyBookList} from "@/api/address/index.js";
import { noticeAdd,noticeUserList} from "@/api/application/index.js";

export default {
  name:"addressAddDialog",

  data() {
    return {
      visible: false,
      addressDatas:[],
      formData: {
        title:"",
        content: "",
        user_app_ids: [],
      },
       rules: {
        title: [
              {required: true, message: '请填写标题', trigger: 'blur'}
          ],
          content: [
              {required: true, message: '请选择内容', trigger: 'blur'}
          ],
          
          user_app_ids: [
              {required: true, message: '请选择用户', trigger: 'blur'}
          ],
          type: [
              {required: true, message: '请选择分享设置', trigger: 'blur'}
          ],
          action_time: [
              {required: true, message: '请选择发送时间', trigger: 'blur'}
          ],

          	
          
       },
       userList:[]
    };
  },

 
  mounted() {
    console.log("asdasdasd")

},
  
  methods: {
    handleClose() {
      this.$refs["form"].resetFields();
      this.visible = false;

    },


    init() {
      this.visible = true;

      this.initAddress();
      
    },
    initAddress(){
      noticeUserList({}).then((response) => {
              console.log(response);
              if(response.code==200){
                this.addressDatas=response.data;
              }else{
                this.$message({
                  message: response.msg?response.msg:"提交失败",
                });
              }
            });
    },
    
    submit(){
      var that=this;

      this.$refs["form"].validate((valid) => {
          if (valid) {
            noticeAdd(that.formData).then((response) => {
              console.log(response);
              if(response.code==200){
                this.$message({
                   message: "提交成功",
                   type: 'success'

                });
                that.$emit('refresh');
                that.visible = false;
              
              }else{
                this.$message({
                  message: response.msg?response.msg:"提交失败",
                });
              }
            });
          } 
        });

    },

    
  },

 
};
</script>

<style lang="scss" scoped>



/deep/ .myWindow {
    border-radius: 15px;



.el-button--primary {
  color: #FFF;
  background-color: #717ebb;
  border-color: #717ebb;
}


/*
.footer{
  display: flex;
  justify-content: center;
}*/


  }
</style>
