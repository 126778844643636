<template>
  <div class='container'>

    <el-row style="margin-bottom:20px">
        <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/application' }">应用中心</el-breadcrumb-item>
        <el-breadcrumb-item >公告</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>

    <p style="text-align:right;margin-bottom:20px">
      <el-button type="primary" @click="add">添加公告</el-button>

    </p>
    <div class="report_main">

        <el-table
          ref="table"
          v-loading="searchLoading"
          :data="pageProps.records"
          element-loading-text="正在查询"
          fit
          stripe
          highlight-current-row
        >

          <el-table-column align="center" label="标题"  prop="title"/>
          <el-table-column align="center"  label="时间" prop="time_str" />
        <el-table-column align="center"  label="状态" prop="str" />


          <el-table-column align="center"  label="操作">

           <template slot-scope="scope">
              <el-button
                size="mini"
                type="text"
                icon="el-icon-edit"
                @click="detailInfo(scope.row.id)"
              >详情</el-button>

            </template>

          </el-table-column>

         
      </el-table>
      <div style="text-align:center;padding:20px">
        <!--分页-->
        <el-pagination
          background
          layout="total, sizes, prev, pager, next, jumper"
          :total="pageProps.total"
          :page-size="pageProps.pageSize"
          :page-sizes="[5, 10, 50, 100]"
          :current-page="pageProps.pageNum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
      
    </div>
    <info-dialog ref="infoDialogRef" @refresh="fetchData"></info-dialog>
    <add-dialog ref="addDialogRef" @refresh="fetchData"></add-dialog>
  </div>
    
</template>

<script>
import infoDialog from "@/views/application/notice/dialog/info-dialog.vue";
import addDialog from "@/views/application/notice/dialog/add-dialog.vue";

//import { jobList,projectList} from "@/api/director/work/recruit/index";
//import { reportList } from "@/api/director/workmaster/index";
import { noticeList} from "@/api/application/index";


  export default {
    components: { infoDialog,addDialog},

    data() {
      return {
       
        searchLoading: false,
        pageProps: {
          records:[],
          total: 0,
          pageSize: 10,
          pageNum: 1
        },

        searchFormData:{
          project_id:"",
          job_id:"",
          type:"1",
          page:0,
          limit:0
        },
        projectDataList:[],
        jobDataList:[],

      }
    },

    mounted() {
    
     this.fetchData();
    },


  methods: {

      add(){
        this.$refs.addDialogRef.init();

      },

      search(){
        this.fetchData();
      },

      fetchData(){
        var that = this;
        that.searchFormData.page=that.pageProps.pageNum;
        that.searchFormData.limit=that.pageProps.pageSize;

        noticeList(that.searchFormData).then((response) => {
          console.log("approval_list",response);
          if(response.code==200){
            that.pageProps.records = response.data.data;
            that.pageProps.total = response.data.total;
          }else{
            that.$message.error(response.msg?response.msg:"请求错误");
          }
         
        });
      },

       // 当前页变更
      handleCurrentChange(num) {
        this.pageProps.pageNum = num
        this.fetchData()
      },


      // 每页显示页面数变更
      handleSizeChange(size) {
        this.pageProps.pageSize = size
        this.fetchData()
      },

      detailInfo(id){
      this.$refs.infoDialogRef.init(id);
    }
      
      
    }
  }
</script>


<style lang="scss" scoped>
.report_nav {
  // height: 72px;
  border-radius: 6px;
  overflow: hidden;
  margin-bottom: 19px;
  ul {
    display: flex;

    li {
      position: relative;
      a {
        padding: 0 24px;
        display: block;
        font-size: 16px;
        &.router-link-active {
          //background-color: #4c71db;
          border-bottom: 1px solid #4c71db;
          color:#4c71db ;
          font-weight: bold;
          //color: #fff;
        }
      }
      aside {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;
        color: #fff;
        background-color: #ff4e4e;
        border-radius: 10px;
        line-height: 20px;
        font-size: 12px;
        text-align: center;
      }
    }

   
  }
}
.report_main {
  border-radius: 6px;
  
}
</style>