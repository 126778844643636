import request from '@/api/request'

//文件上传
export function uploadFile(data) {
    return request({
        url: '/web/file/Upload',
        method: 'POST',
        data
    })
}

//PC企业端-全部项目列表

export function projectList(data) {
    return request({
        url: '/company/company/clock/project/list',
        method: 'GET',
        params:data
    })
}

// /PC企业端-全部职位列表
export function jobList(data) {
    return request({
        url: '/company/company/clock/job/list',
        method: 'GET',
        params:data
    })
}

//战报列表
export function reportList(data) {
    return request({
        url: '/company/company/report/list',
        method: 'GET',
        params:data
    })
}


//战报x详情
export function reportInfo(data) {
    return request({
        url: '/company/company/report/info',
        method: 'GET',
        params:data
    })
}

//PC企业端-战报评价
export function reportAddCommon(data) {
    return request({
        url: '/company/company/report/addCommon',
        method: 'POST',
        data
    })
}


//PC企业端-审批列表
export function leaveList(data) {
    return request({
        url: '/company/company/leave/list',
        method: 'GET',
        params:data
    })
}



export function leaveInfo(data) {
    return request({
        url: '/company/company/leave/info',
        method: 'GET',
        params:data
    })
}

export function leaveUpdate(data) {
    return request({
        url: '/company/company/leave/update',
        method: 'POST',
        data
    })
}

export function clockDayList(data) {
    return request({
        url: '/company/company/clock/day/list',
        method: 'GET',
        params:data
    })
}


export function clockMonthList(data) {
    return request({
        url: '/company/company/clock/month/list',
        method: 'GET',
        params:data
    })
}





export function noticeUserList(data) {
    return request({
        url: '/company/company/notice/get/user/list',
        method: 'GET',
        params:data
    })
}

export function noticeList(data) {
    return request({
        url: '/company/company/notice/list',
        method: 'GET',
        params:data
    })
}


export function noticeAdd(data) {
    return request({
        url: '/company/company/notice/add',
        method: 'POST',
        data
    })
}



export function noticeInfo(data) {
    return request({
        url: '/company/company/notice/info',
        method: 'GET',
        params:data
    })
}


