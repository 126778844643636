<template>
  <el-dialog
    :visible.sync="visible"
    append-to-body
    :show-close="true"
    :center="true"
    :before-close="handleClose"
    title="公告详情"
    width="500px"
  >
    <div>
      <el-form ref="form" :model="formData"  :rules="rules" label-width="100px">

       <el-form-item label="标题" prop="title">
        {{ formData.title }}
       </el-form-item>

       <el-form-item label="内容" prop="content">
        {{ formData.content }}
       </el-form-item>
    
       <el-form-item label="发送时间" prop="action_time">
        {{ formData.action_time }}
       </el-form-item>
       
       <el-form-item label="发送用户" >
         <span v-for="(item,index ) in formData.user" :key="index">
            {{ item.nickname }}
          </span>
       </el-form-item>




       <!--<div class="footer">
         <el-button size="medium" type="primary" @click="submit" >提交</el-button>
         <el-button size="medium" type="primary" @click="cancel" >关闭</el-button>
       </div>-->
     </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
    </span>


  </el-dialog>
</template>

<script>

import { companyBookList} from "@/api/address/index.js";
import { noticeAdd,noticeInfo} from "@/api/application/index.js";

export default {
  name:"addressAddDialog",

  data() {
    return {
      visible: false,
      addressDatas:[],
      formData: {
        title:"",
        content: "",
        user_app_ids: [],
      },
       rules: {
        title: [
              {required: true, message: '请填写标题', trigger: 'blur'}
          ],
          content: [
              {required: true, message: '请选择内容', trigger: 'blur'}
          ],
          
          user_app_ids: [
              {required: true, message: '请选择用户', trigger: 'blur'}
          ],
          
        
       },
       userList:[]
    };
  },

 
  mounted() {
    console.log("asdasdasd")

},
  
  methods: {
    handleClose() {
      this.$refs["form"].resetFields();
      this.visible = false;

    },


    init(id) {
      this.visible = true;
      this.id=id;

      this.initNotice();
      
    },
   
    

    initNotice(){
      noticeInfo({id:this.id}).then((response) => {
              console.log(response);
              if(response.code==200){
                this.formData=response.data;
              }else{
                this.$message({
                  message: response.msg?response.msg:"提交失败",
                });
              }
            });
    },
    
    submit(){
      var that=this;

      this.$refs["form"].validate((valid) => {
          if (valid) {
            noticeAdd(that.formData).then((response) => {
              console.log(response);
              if(response.code==200){
                this.$message({
                   message: "提交成功",
                   type: 'success'

                });
                that.$emit('refresh');
                that.visible = false;
              
              }else{
                this.$message({
                  message: response.msg?response.msg:"提交失败",
                });
              }
            });
          } 
        });

    },

    
  },

 
};
</script>

<style lang="scss" scoped>



/deep/ .myWindow {
    border-radius: 15px;



.el-button--primary {
  color: #FFF;
  background-color: #717ebb;
  border-color: #717ebb;
}


/*
.footer{
  display: flex;
  justify-content: center;
}*/


  }
</style>
